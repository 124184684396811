var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-layout',[_c('details-page-title',{attrs:{"title":"Marketing Campaigns","dropdown-text-button":"Campaign Status","details-id":_vm.id}},[_c('MoreActions',{attrs:{"current-status":_vm.details.is_active},on:{"change":_vm.UpdateStatus}})],1),_c('DetailsPageHeader',{attrs:{"currencySymbol":_vm.currencySymbol,"voucher":_vm.details}}),_c('content-section',{attrs:{"spacing":false}},[_c('div',{staticClass:"content-spacing"},[_c('div',{staticClass:"grid grid-cols-1 mb-10 md:grid-cols-2"},[_c('TitlePlus',{attrs:{"title":"Campaign Summary"},on:{"plus":function($event){return _vm.onEdgeStack({
              esId: _vm.invite.esId,
              primaryKey: _vm.id,
              rawData: _vm.details,
            })}}})],1),_c('div',{staticClass:"summary-card-container"},_vm._l((_vm.getSummaries),function(summary){return _c('SummaryCard',{key:"SummaryCard-" + summary.title,attrs:{"title":summary.title,"value":summary.value,"variant":summary.variant,"icon":summary.variant}})}),1)]),_c('FSTable',{attrs:{"qso":_vm.qso,"fst-id":_vm.fstId,"headers":_vm.tableHeaders,"endpoint":_vm.indexDataEndpoint,"text-fallback-always":true},on:{"meta":function (e) { return (_vm.indexMetaData = e); }},scopedSlots:_vm._u([{key:"topLeft",fn:function(ref){
            var slotWidth = ref.slotWidth;
return [_c('keep-alive',[_c('FSTableFilter',{attrs:{"fst-id":_vm.fstId,"slot-width":slotWidth,"options":_vm.filterOptions}})],1)]}},{key:"default",fn:function(ref){
            var data = ref.data;
return _vm._l((data),function(item,itemIndex){return _c('FSTableRow',{key:itemIndex,attrs:{"textFallbackAlways":""}},[_c('FSTableRowItem',{attrs:{"text":("#" + (item.id)),"truncate":-5}}),_c('FSTableRowItem',{attrs:{"text":item.rider.full_name,"to":{
              name: 'ViewRiderUserProfile',
              params: { id: item.rider.id },
            }}}),_c('FSTableRowItem',{attrs:{"text":item.rider.device_os}}),_c('FSTableRowItem',{attrs:{"text":item.rider.ip_address}}),_c('FSTableRowItem',[_c('TimeAgo',{attrs:{"title":item.updated_at,"datetime":item.updated_at,"auto-update":60}})],1),_c('FSTableRowItem',[_c('x-status',{attrs:{"text":item.status,"variant":_vm.getItemVarient(item.status)}})],1)],1)})}}])}),_c('NotifyRider',{attrs:{"esId":_vm.invite.esId,"busy":_vm.invite.busy,"riderData":_vm.rider,"rawData":_vm.details,"fleets":_vm.fleets,"primaryKey":_vm.invite.primaryKey,"fleetList":_vm.fleetList,"currencies":_vm.currencies},on:{"edgestack":function($event){return _vm.onEdgeStack({ esId: _vm.addEdit.esId, primaryKey: $event.taskId })}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }