<template>
  <base-layout>
    <details-page-title
      title="Marketing Campaigns"
      dropdown-text-button="Campaign Status"
      :details-id="id"
    >
      <MoreActions :current-status="details.is_active" @change="UpdateStatus" />
    </details-page-title>
    <DetailsPageHeader :currencySymbol="currencySymbol" :voucher="details" />

    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <TitlePlus
            title="Campaign Summary"
            @plus="
              onEdgeStack({
                esId: invite.esId,
                primaryKey: id,
                rawData: details,
              })
            "
          />
        </div>

        <div class="summary-card-container">
          <SummaryCard
            v-for="summary in getSummaries"
            :key="`SummaryCard-` + summary.title"
            :title="summary.title"
            :value="summary.value"
            :variant="summary.variant"
            :icon="summary.variant"
          />
        </div>
      </div>

      <FSTable
        :qso="qso"
        :fst-id="fstId"
        :headers="tableHeaders"
        :endpoint="indexDataEndpoint"
        :text-fallback-always="true"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="fstId"
              :slot-width="slotWidth"
              :options="filterOptions"
            />
          </keep-alive>
        </template>

        <template #default="{ data }">
          <FSTableRow
            v-for="(item, itemIndex) in data"
            :key="itemIndex"
            textFallbackAlways
          >
            <FSTableRowItem :text="`#${item.id}`" :truncate="-5" />

            <FSTableRowItem
              :text="item.rider.full_name"
              :to="{
                name: 'ViewRiderUserProfile',
                params: { id: item.rider.id },
              }"
            />
            <FSTableRowItem :text="item.rider.device_os" />
            <FSTableRowItem :text="item.rider.ip_address" />
            <FSTableRowItem>
              <TimeAgo
                :title="item.updated_at"
                :datetime="item.updated_at"
                :auto-update="60"
              />
            </FSTableRowItem>

            <FSTableRowItem>
              <x-status
                :text="item.status"
                :variant="getItemVarient(item.status)"
              />
            </FSTableRowItem>
          </FSTableRow>
        </template>
      </FSTable>
      <NotifyRider
        :esId="invite.esId"
        :busy="invite.busy"
        :riderData="rider"
        :rawData="details"
        :fleets="fleets"
        :primaryKey="invite.primaryKey"
        :fleetList="fleetList"
        :currencies="currencies"
        @edgestack="
          onEdgeStack({ esId: addEdit.esId, primaryKey: $event.taskId })
        "
      />
    </content-section>
  </base-layout>
</template>

<script>
import { formatSummaries } from './helpers'
import DetailsPageHeader from './DetailsPageHeader.vue'
import xMan from '@/utils/xMan'
import BaseLayout from '@/views/shared/BaseLayout.vue'
import DetailsPageTitle from '@/components/ui/DetailsPageTitle.vue'
import SummaryCard from '@/components/cards/SummaryCard'
import XStatus from '@/components/badge/XStatus'
import { MarketingCampaignConfig } from '@/config/MarketingCampaignConfig'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus.vue'
import NotifyRider from '../NotifyRider'
import MoreActions from './MoreActions'
import { useEdgeStack } from '@/plugins/edgeStack'

import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'

export default {
  name: 'VoucherDetails',
  components: {
    BaseLayout,
    DetailsPageTitle,
    DetailsPageHeader,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,
    SummaryCard,
    XStatus,
    TitlePlus,
    ContentSection,
    NotifyRider,
    MoreActions,
  },
  data() {
    return {
      details: {},
      indexMetaData: {
        summary: {
          total_expired_campaign: 0,
          total_failed_campaign: 0,
          total_pushed_campaign: 0,
          total_sent_campaign: 0,
        },
      },
      invite: {
        esId: 'notifyRider',
        busy: false,
        rawData: null,
        vehicleOptions: null,
        primaryKey: null,
      },
      qso: { append: '', prepend: '?' },
      fstId: 'voucherSummary',
      filterOptions: [
        {
          key: 'status',
          type: 'checkbox',
          input: [
            { text: 'Invited', value: 'Invited' },
            { text: 'Success', value: 'Success' },
            { text: 'Failed', value: 'Failed' },
            // { text: 'Expired', value: 'Expired' },
          ],
          title: 'Status',
        },
      ],
      id: '',
      indexDataEndpoint: '',
      tableHeaders: [
        { text: 'Invitation ID', width: '10%', sort: null },
        { text: 'Rider Name', width: '30%', sort: null },
        { text: 'Device OS', width: '15%', sort: null },
        { text: 'IP Address', width: '20%', sort: null },
        { text: 'Last Attempted', width: '10%', sort: null },
        { text: 'Status', width: '15%', sort: null },
      ],
      dateFormat: {
        format: 'DD MMM YY',
      },
      fleets: [],
      rider: {
        assigned_fleets: [],
        invitation_type: false,
        rider: '',
        selected_riders: [],
      },
      fleetList: [],
      currencies: [],
    }
  },
  computed: {
    currencySymbol() {
      return this?.$org?.default_currency?.symbol || '$'
    },
    getSummaries() {
      return formatSummaries(this.indexMetaData.summary)
    },
  },
  created() {
    this.id = this.$route.params.id
    this.indexDataEndpoint = MarketingCampaignConfig.api.history(this.id)
    this.getVoucherDetails()
    this.getFleets()
  },
  methods: {
    async getFleets() {
      let { data } = await this.$http.get('/dashboard/fleets/?dropdown')
      if (data) {
        this.fleetList = data.data
        const currencyList = []
        data.data.forEach((fleet) => {
          const result = currencyList.find(
            (row) => fleet.country.code3 === row.text
          )
          if (!result) {
            currencyList.push({
              text: fleet.country.code3,
              value: fleet.country.currency_symbol,
            })
          }
        })
        this.currencies = currencyList
      }
    },
    async onEdgeStack({
      esId = this.invite.esId,
      esAction = 'toggle',
      // eslint-disable-next-line no-unused-vars
      primaryKey = null,
      rawData = null,
    } = {}) {
      const edgeStack = useEdgeStack()
      this.rider.rider = ''
      this.rider.invitation_type = false
      this.rider.assigned_fleets = []

      // open the edgestack first, load data asynchronally if primary key is provided
      if (esAction === 'toggle') {
        edgeStack.toggle(esId)
      } else if (esAction === 'open') {
        edgeStack.open(esId)
      } else if (esAction === 'close') {
        edgeStack.close(esId)
      } else {
        throw new Error(`Unknown esAction: ${esAction}`)
      }

      // immidiately make edgestack busy & fetch & provide data if primary key is provided
      if (primaryKey && esId === this.invite.esId) {
        // view mode
        this.invite.busy = true
        this.invite.primaryKey = primaryKey
        this.invite.rawData = rawData
        this.getFleetOptions()
      }
    },
    getFleetOptions() {
      let fleets = this.details?.assigned_fleets.map(function(row) {
        return {
          text: row.name,
          value: row.id,
        }
      })
      this.fleets = fleets
    },
    onApplyFilterDateRange,
    getRedeemedAt(redeemed_at) {
      if (redeemed_at) {
        return this.$UTCAwareTime(redeemed_at, this.dateFormat)
      }
      return '--'
    },
    getItemVarient(status) {
      if (status === 'Success') return 'green'
      if (status === 'Failed') return 'red'
      return 'blue'
    },
    async getVoucherDetails() {
      let { data } = await this.$http.get(
        MarketingCampaignConfig.api.detail(this.id)
      )
      if (data) {
        this.details = data
      }
    },
    async UpdateStatus(status) {
      this.isLoading = true
      let data = new xMan({ is_active: status }).toFormData()
      await this.$http
        .patch(MarketingCampaignConfig.api.update(this.id), data)
        .then((res) => {
          console.log(res)
          this.getVoucherDetails()
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Success',
              text: 'Campaign status has updated',
            },
            3000
          )
        })
        .catch((err) => {
          console.log('id-ver-err', err)
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: 'Failed',
              text: 'Failed to update status',
            },
            3000
          )
        })
        .finally(() => {
          this.isLoading = false
        })
      return true
    },
  },
}
</script>
